import { css } from '@emotion/react'

const AntdFixStyles = css`
  .ant-message {
    .ant-message-notice {
      text-align: left;
    }

    .anticon {
      font-size: 1rem;
    }
  }

  .ant-modal-title {
    font-size: 1.25rem;
  }

  .ant-model-body {
    padding: 1.5rem 0.6rem;
  }

  .ant-modal-close-x {
    font-size: 1.25rem;
  }

  .ant-btn {
    padding: 0 15px;
  }

  .ant-btn-sm {
    font-size: 0.9rem;
  }

  .ant-btn-lg {
    font-size: 1rem;
  }
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only)
    .anticon:not(:last-child) {
    margin-left: 0;
  }

  .ant-input {
    padding: 4px 11px;
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-alert-icon {
    top: 0.8rem;
    left: 0.85rem;
  }

  .ant-tooltip-wide {
    .ant-tooltip-inner {
      min-width: 31rem;
    }
  }
`

export default AntdFixStyles
