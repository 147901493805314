import React from 'react'

import GlobalInfo from 'components/GlobalInfo'
import useAEM from 'hooks/useAEM'

const AuthenticatedRoute = ({ children }) => {
  const { status, isReady } = useAEM()

  return isReady ? children : <GlobalInfo title={status.title} text={status.message} />
}

export default AuthenticatedRoute
