/**
 * constants configuration file
 */
export default {
  LOGIN_TOKEN_COOKIE_KEY: 'login-token'
}

export const INTERNAL_ERROR_PREFIX = 'X-Internal: '

export const DEFAULT_LANGUAGES = ['no', 'sv']
export const SUPPORTED_LANGUAGES = ['no', 'en', 'sv']

export const AUTHOR_URL =
  new URLSearchParams(document.location.search).get('aemmode') === 'local'
    ? `http://localhost:4502`
    : `${import.meta.env.REACT_APP_AEM_AUTHOR}`
