import React from 'react'

import AuthenticatedRoute from 'components/router/AuthenticatedRoute'
import PageBuilder from 'modules/PageBuilder'

const PageBuilderPage = (props) => {
  const contentPath = `/${props['*']}`
  return (
    <AuthenticatedRoute>
      <PageBuilder contentPath={contentPath} />
    </AuthenticatedRoute>
  )
}

export default PageBuilderPage
