import { message } from 'antd'
import { Dispatch } from 'redux'

import types from 'redux/constants/ConnectivityReducerConstants'
import { ActionsType } from 'redux/types'
import getDataFromAEM from 'utils/getDataFromAEM'
import postDataToAem from 'utils/postDataToAem'

const hasMockServer = `${import.meta.env.REACT_APP_USE_MOCK_SERVER}` === 'true'

type ConnectivityActionsType = ActionsType<typeof types>

// We need to get initial state for Aem-Dcm Connectivity from Aem
export const getInitialStateDcmConnectivity = () => (
  dispatch: Dispatch<ConnectivityActionsType>
) => {
  if (hasMockServer) {
    // In case of mock server we can skip checking DCM connectivity
    dispatch({ type: types.CONNECTIVITY_UPDATE_STATUS, payload: false })
    return
  }

  dispatch({ type: types.CONNECTIVITY_LOADING })
  return getDataFromAEM(`/bin/connectivity`)
    .then(({ dcmConnectivityEnabled }) => {
      dispatch({ type: types.CONNECTIVITY_UPDATE_STATUS, payload: dcmConnectivityEnabled })
      !dcmConnectivityEnabled &&
        message.warning('Aem-Dcm Connectivity is OFF. Saving is not avaliable', 5)
    })
    .catch((err) => {
      const errorMessage = `AEM Error! Failed to get Aem-Dcm Connectivity`
      dispatch({ type: types.CONNECTIVITY_ERROR, payload: err })
      console.error(err)
      message.error(errorMessage)
    })
}

// We need to update state for Aem-Dcm Connectivity in Aem
export const updateStateDcmConnectivity = (connectivity: boolean) => (
  dispatch: Dispatch<ConnectivityActionsType>
) => {
  dispatch({ type: types.CONNECTIVITY_LOADING })
  return postDataToAem(`/bin/connectivity`, { dcmConnectivityEnabled: connectivity })
    .then(({ dcmConnectivityEnabled }) => {
      dispatch({ type: types.CONNECTIVITY_UPDATE_STATUS, payload: dcmConnectivityEnabled })
      dcmConnectivityEnabled
        ? message.success('UPDATE: Aem-Dcm Connectivity is ON. Autosaving is avaliable again.', 5)
        : message.warning('UPDATE: Aem-Dcm Connectivity is OFF. Saving is not avaliable.', 5)
    })
    .catch((err) => {
      const errorMessage = `AEM Error! Failed to update Aem-Dcm Connectivity`
      dispatch({ type: types.CONNECTIVITY_ERROR, payload: err })
      console.error(err)
      message.error(errorMessage)
    })
}

export default {
  getInitialStateDcmConnectivity,
  updateStateDcmConnectivity
}
