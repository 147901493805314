import React from 'react'

import styled from '@emotion/styled'
import { Link } from '@reach/router'

import Logo from 'components/Logo'
import AuthenticatedRoute from 'components/router/AuthenticatedRoute'

const Center = styled.div`
  position: fixed;
  top: 55%;
  bottom: 0;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  background: var(--color-white);
`

const DCMPageList = styled.ul`
  padding: 0;
  max-width: 32rem;
  margin: 0 auto;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  li {
    a {
      background: rgba(0, 114, 114, 0);
      display: inline-block;
      padding: 0.5rem 1rem;
      border-radius: 0.35rem;
      font-size: 1.15rem;
      color: #162222;
      transition: all 0.2s;

      &:hover,
      &:focus {
        outline: 0;
        background: rgba(0, 114, 114, 0.1);
        color: #007272;
      }
    }
  }
`

const pages = [
  { label: 'Page Builder', path: '/builder' },
  { label: 'Publisher', path: '/publisher' },
  { label: 'Content Accuracy', path: '/contentaccuracy' }
]

const HomePage = () => {
  return (
    <AuthenticatedRoute>
      <Center>
        <Logo />
        <DCMPageList>
          {pages.map(({ label, path }) => (
            <li key={path}>
              <Link to={path}>{label}</Link>
            </li>
          ))}
        </DCMPageList>
      </Center>
    </AuthenticatedRoute>
  )
}

export default HomePage
