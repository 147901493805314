import React from 'react'

import '@dnb/eufemia/style'
import 'antd/dist/antd.variable.less'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import ReactDOM from 'react-dom'

import App from 'pages'

dayjs.extend(localizedFormat)

ReactDOM.render(<App />, document.getElementById('root'))

// set Application version
try {
  const version = import.meta.env.REACT_APP_VERSION
  if (version) {
    const versionMeta = document.createElement('meta')
    versionMeta.setAttribute('name', 'version')
    versionMeta.setAttribute('content', version)
    document.head.appendChild(versionMeta)
  }
} catch (e) {
  // eslint-disable-next-line
  console.log('version setup', e)
}
