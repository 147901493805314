import React, { memo, useState, Suspense, lazy } from 'react'

import { useStore } from 'react-redux'

import GlobalInfo from 'components/GlobalInfo'

const Module = (props) => {
  const store = useStore()
  const { load, title, ...otherProps } = props

  const [Component] = useState(
    lazy(() =>
      load().then((mod) => {
        // Extract the reducer, key & component
        const { reducer, key, component, reducers = [] } = mod.default
        // Push reducer to reducers list
        if (reducer && key) {
          reducers.push({ key, reducer })
        }
        // Inject the reducer to the store
        reducers.forEach(({ key, reducer }) => store.addReducer(key, reducer))
        // Return the component as an esModule
        return { default: component }
      })
    )
  )

  return (
    <Suspense fallback={<GlobalInfo title="Loading" text={`Initializing ${title}`} />}>
      <Component {...otherProps} />
    </Suspense>
  )
}
export default memo(Module)
