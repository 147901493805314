import React, { useEffect } from 'react'

import { Theme } from '@dnb/eufemia/shared'
import { Global } from '@emotion/react'
import { Router } from '@reach/router'
import { ConfigProvider } from 'antd'
import { Provider as ReduxProvider } from 'react-redux'
import GlobalStyles from 'styles'

import DCMConnectivity from 'components/DCMConnectivity'
import useAppSelector from 'hooks/useAppSelector'
import store from 'redux/store'

import ContentAccuracy from './ContentAccuracyPage/index'
import Home from './HomePage/index'
import NotFound from './NotFound/index'
import PageBuilder from './PageBuilderPage/index'
import Publisher from './PublisherPage/index'

const DCMThemeProvider = ({ children }) => {
  const isSbanken = useAppSelector((state) => state.PageBuilder?.builderTheme) === 'sbanken'

  useEffect(() => {
    const dnbPrimary = '#007272' //DNB Sea green
    const sbankenPrimary = '#222163' //SB Purple alternative

    const primaryColor = isSbanken ? sbankenPrimary : dnbPrimary
    ConfigProvider.config({
      theme: {
        primaryColor: primaryColor
      }
    })
  }, [isSbanken])
  return (
    <ConfigProvider>
      <Global styles={() => GlobalStyles(isSbanken)} />
      <Theme name={isSbanken ? 'sbanken' : 'ui'}>{children}</Theme>
    </ConfigProvider>
  )
}

const Pages = () => {
  return (
    <>
      <ReduxProvider store={store}>
        <DCMThemeProvider>
          <DCMConnectivity />
          <div id="DCM">
            <Router>
              <Home path="/" />
              <PageBuilder path="builder/*" />
              <Publisher path="publisher/*" />
              <ContentAccuracy path="contentaccuracy/*" />
              <NotFound default />
            </Router>
          </div>
        </DCMThemeProvider>
      </ReduxProvider>
    </>
  )
}

export default Pages
