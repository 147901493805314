import { css } from '@emotion/react'

import AntdFixStyles from './AntdFixStyles'

const GlobalStyles = (isSbanken: boolean) => css`
  :root {
    --border-default: 0.0625rem solid #efefef;
    --dcm-background-color: #e6edee;

    --sb-color-gray-dark: #9292b0;
    --sb-color-gray-dark-2: #62628e;
    --sb-color-gray-dark-3: #3a3970;
    --sb-color-purple-alternative: #222163;
    --sb-color-purple: #1c1b4e;

    --theme-color-primary: ${isSbanken
      ? 'var(--sb-color-gray-dark-3)'
      : 'var(--color-emerald-green)'};

    --theme-color-secondary: ${isSbanken
      ? 'var(--sb-color-gray-dark-2)'
      : 'var(--color-sea-green)'};

    --theme-field-wrapper-anchor-color: ${isSbanken
      ? 'var(--sb-color-gray-dark-3)'
      : 'var(--color-sea-green)'};

    --theme-header-container-background-color: ${isSbanken
      ? 'var(--sb-color-purple-alternative)'
      : 'var(--color-sea-green)'};

    --theme-footer-container-background-color: ${isSbanken
      ? 'var(--sb-color-purple)'
      : 'var(--color-ocean-green)'};

    --theme-toolbar-button-hover-background-color: ${isSbanken
      ? 'var(--sb-color-gray-dark)'
      : 'var(--color-sea-green-30)'};

    --theme-preview-fullscreen-handler-background-color: ${isSbanken
      ? 'var(--sb-color-purple)'
      : 'var(--color-ocean-green)'};
  }

  body {
    font-family: DNB, 'Helvetica', 'Roboto', 'Arial', sans-serif !important;
    ${AntdFixStyles}
  }

  img {
    max-width: 100%;
  }

  .anticon svg {
    vertical-align: baseline;
  }
`

export default GlobalStyles
