import React from 'react'

const builderExampleURL = `${window.location.origin}/builder/content/web/boliglaan`

const errors = {
  400: {
    isError: true,
    title: 'Bad Request',
    message: (
      <>
        Content path is not provided or is invalid
        <br />
        <br /> Example of a correct URL: <br />
        <a href={builderExampleURL}>
          <small>{builderExampleURL}</small>
        </a>
      </>
    )
  },
  403: {
    isError: true,
    title: 'Not Authorized',
    message: 'You are not authorized to access this page'
  },
  404: {
    isError: true,
    title: "Oops! We can't find the page you're looking for",
    message: (
      <>
        Have we messed up with the links? Try again or <a href="/">go to home page</a>.
      </>
    )
  },
  500: {
    isError: true,
    title: 'Technical error',
    message: 'DNB Content Manager is not working properly at the moment, please try again later'
  }
}

const schema = {
  UNAVAILABLE_SCHEMA: {
    isError: true,
    title: 'Invalid schema',
    message: 'Schema could not be loaded.'
  },
  LOADING_SCHEMA: {
    isError: false,
    title: 'Loading',
    message: 'Please wait while we load page schema'
  }
}

const aem = {
  LOADING_AEM_CONTENT: {
    isError: false,
    title: 'Loading',
    message: 'Please wait while we load AEM page data'
  },
  ERROR_AEM_CONTENT: {
    isError: true,
    title: 'Page Not Found',
    message: "It seems that the requested page doesn't exist in AEM"
  }
}

export default {
  ...errors,
  ...aem,
  ...schema
}
