import { composeWithDevTools } from '@redux-devtools/extension'
import { applyMiddleware, createStore, combineReducers, ReducersMapObject } from 'redux'
import thunk from 'redux-thunk'

import AppReducer from './reducers/AppReducer'
import ConnectivityReducer from './reducers/ConnectivityReducer/ConnectivityReducer'
import UserReducer from './reducers/UserReducer'
import { AppStoreType, DynamicReducersStateType, StaticReducersStateType } from './types'

// Define the Reducers that will always be present in the application
const staticReducers: ReducersMapObject<StaticReducersStateType> = {
  App: AppReducer,
  User: UserReducer,
  Connectivity: ConnectivityReducer
}

// Handy function for combining dynamic reducers with the static reducers.
function createReducer(dynamicReducers: ReducersMapObject<Partial<DynamicReducersStateType>> = {}) {
  return combineReducers({
    ...staticReducers,
    ...dynamicReducers
  })
}

// Create middleware
// Thunk middleware allows to write action creators that return a function instead of an action
const middleware = applyMiddleware(thunk)

// Check if its dev environment
const enableDevTools =
  import.meta.env.MODE === 'development' || import.meta.env.REACT_APP_ENABLE_DEV_TOOLS

// Create the store object
const store: AppStoreType = enableDevTools
  ? createStore(createReducer(), composeWithDevTools(middleware))
  : createStore(createReducer(), middleware)

// Add a dictionary to keep track of the registered dynamic reducers
store.dynamicReducers = {}

// Add reducer function
// This function adds the dynamic reducer, and creates a new combined reducer
store.addReducer = (key, dynamicReducer) => {
  // Check if it wasn't added already
  if (!!store.dynamicReducers && !store.dynamicReducers[key]) {
    store.dynamicReducers[key] = dynamicReducer
    store.replaceReducer(createReducer(store.dynamicReducers))
  }
}

// Remove reducer function
// This function removes an dynamic reducer, and creates a new combined reducer
store.removeReducer = (key) => {
  // Check if it is added
  if (!!store.dynamicReducers && store.dynamicReducers[key]) {
    delete store.dynamicReducers[key]
    store.replaceReducer(createReducer(store.dynamicReducers))
  }
}

export default store
