import { FC, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { getInitialStateDcmConnectivity } from 'redux/actions/ConnectivityActions'

const DCMConnectivity: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getInitialStateDcmConnectivity())
  }, [])
  return null
}

export default DCMConnectivity
