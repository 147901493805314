import { Draft, produce } from 'immer'
/**
 * Default reducer function
 */

export type Cases<T> = {
  [x: string]: (state: Draft<T>, status: any) => void
}

export default <T>(cases: Cases<T>, initialState: T) =>
  produce((draft: Draft<T>, action) => {
    const { type, payload } = action
    const selectedCase = cases[type]
    if (selectedCase) {
      return selectedCase(draft, payload)
    }
  }, initialState)
