import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'

import Logo from 'components/Logo'
import status from 'constants/status'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-white);
`

const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: auto;
  text-align: center;
  margin: 10vh auto;
  max-width: 36rem;
`

const Header = styled.h1<{ isError: boolean }>`
  ${({ isError }) => isError && 'color: var(--color-fire-red);'}
`

interface GlobalInfoProps {
  statusCode?: string | number
  title?: string
  text?: string
}

const GlobalInfo: FunctionComponent<GlobalInfoProps> = ({ statusCode, title, text }) => {
  const statusData = statusCode ? status[statusCode] : false
  const heading = statusData ? (title ? title : statusData.title) : title
  const message = statusData ? (text ? text : statusData.message) : text

  return (
    <Wrapper>
      <InnerWrapper>
        <Logo />
        <Header isError={statusData.isError}>{heading}</Header>
        {message && <p>{message}</p>}
      </InnerWrapper>
    </Wrapper>
  )
}

export default GlobalInfo
