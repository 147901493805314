import React from 'react'

import AuthenticatedRoute from 'components/router/AuthenticatedRoute'
import ContentAccuracy from 'modules/ContentAccuracy'

const ContentAccuracyPage = () => {
  return (
    <AuthenticatedRoute>
      <ContentAccuracy />
    </AuthenticatedRoute>
  )
}

export default ContentAccuracyPage
