import constants, { appStatus } from 'redux/constants/AppReducerConstants'
import Reducer, { Cases } from 'redux/reducer'

import { AppState } from './types'

const initialState: AppState = {
  status: appStatus.INITIALIZING,
  siteUrl: '',
  previewMode: false,
  previewModeButtonVisible: true
}

const cases: Cases<AppState> = {
  [constants.APP_CHANGE_STATUS]: (state, status) => {
    state.status = status
  },
  [constants.APP_SET_SITE_URL]: (state, siteUrl) => {
    state.siteUrl = siteUrl
  },
  [constants.APP_PREVIEW_BUTTON_TOGGLE]: (state, isVisible) => {
    state.previewModeButtonVisible = isVisible
    if (!isVisible) {
      state.previewMode = true
    }
  },
  [constants.APP_PREVIEW_TOGGLE]: (state) => {
    state.previewMode = !state.previewMode
  }
}

export default Reducer(cases, initialState)
