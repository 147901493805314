import React from 'react'

import styled from '@emotion/styled'
import { Link } from '@reach/router'
import LogoSvg from 'assets/images/DCM-Logo.svg'

const LogoWrapper = styled.div`
  max-width: 15rem;
  margin: 0 auto 8rem;

  img {
    max-width: 100%;
  }
`

const Logo = () => (
  <LogoWrapper>
    <Link to="/">
      <img src={LogoSvg} alt="DNB Content Manager" />
    </Link>
  </LogoWrapper>
)

export default Logo
