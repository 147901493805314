import React from 'react'

import AuthenticatedRoute from 'components/router/AuthenticatedRoute'
import Publisher from 'modules/Publisher'

const PublisherPage = () => {
  return (
    <AuthenticatedRoute>
      <Publisher />
    </AuthenticatedRoute>
  )
}

export default PublisherPage
