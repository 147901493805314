import constants from 'redux/constants/UserReducerConstants'
import Reducer, { Cases } from 'redux/reducer'

import { UserState } from './types'

const initialState: UserState = {
  currentUser: {
    authorizableId: null,
    authorizableId_xss: null,
    declaredMemberOf: [{ authorizableId: null, name: null }],
    isImpersonated: false,
    name: null,
    name_xss: null,
    home: null,
    type: null,
    image: null
  }
}

const cases: Cases<UserState> = {
  [constants.USER_LOAD]: (state, userData) => {
    state.currentUser = userData
  }
}

export default Reducer(cases, initialState)
