type ResponseMessage = {
  type: string
  key: string
  data?: any
  status?: string
}

type OptionalResponseMessage = null | undefined | Partial<ResponseMessage>

type MessageToParent = {
  key: string
  [x: string]: any
}

type Handler = (
  message: ResponseMessage,
  resolve: (value: any) => void,
  reject: (reason?: any) => void
) => void

export const postMessageToParent = <PromiseResponse = unknown>(
  messageToParent: MessageToParent,
  targetOrigin: string,
  handler: Handler
) => {
  return new Promise<PromiseResponse>((resolve, reject) => {
    const handleIncomingMessage = (e: MessageEvent<OptionalResponseMessage>) => {
      const message = e.data
      if (
        message &&
        message.type &&
        message.type === 'RESPONSE' &&
        message.key === messageToParent.key
      ) {
        window.removeEventListener('message', handleIncomingMessage)
        // we need to force ResponseMessage, because typescript does not take in count checks in `if` statement
        handler(message as ResponseMessage, resolve, reject)
      }
    }
    window.addEventListener('message', handleIncomingMessage)
    window.parent.postMessage(messageToParent, targetOrigin)
  })
}
