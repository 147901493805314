import constants from 'redux/constants/ConnectivityReducerConstants'
import Reducer, { Cases } from 'redux/reducer'

import { ConnectivityState } from './types'

const initialState: ConnectivityState = {
  loadingDcmConnectivity: false,
  connectivityError: null
}

const cases: Cases<ConnectivityState> = {
  [constants.CONNECTIVITY_UPDATE_STATUS]: (state, connectivity) => {
    state.connectivity = connectivity
    state.connectivityError = null
    state.loadingDcmConnectivity = false
  },
  [constants.CONNECTIVITY_LOADING]: (state) => {
    state.loadingDcmConnectivity = true
    state.connectivityError = null
  },
  [constants.CONNECTIVITY_ERROR]: (state, error) => {
    state.connectivityError = error
    state.loadingDcmConnectivity = false
  }
}

export default Reducer(cases, initialState)
